.landing-page {
  .top-banner {
    height: 100vh;
    height: -webkit-fill-available;
    align-items: center;

    h1 {
      font-size: 54px;
      margin-bottom: 30px;
      @media (max-width: 1280px) {
        font-size: 36px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 80px;
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 30px;
      }
      @media (max-width: 600px) {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    #landing-page-image {
      margin-top: -70px;
      min-width: 50vw;
      max-width: calc(100% + 65px);
      width: 100%;
    }

    .actions {
      display: flex;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        width: 100%;
        justify-content: space-between;
      }

      .top-banner-signin-btn {
        background: $primary-color;
        padding: 7px 30px !important;
        margin-right: 30px;
        @media (max-width: 600px) {
          margin-right: 5px;
        }
      }

      a {
        text-decoration: none;
      }

      .register-btn {
        // border-bottom: 1px solid #a4a3ee;
        // border-radius: 0 !important;
        span {
          color: #a4a3ee !important;
        }
        .MuiButton-endIcon {
          margin-left: 20px;
          @media (max-width: 600px) {
            margin-left: 5px;
          }
        }
      }
    }

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }
  .video-tool-banner {
    padding: 20px 35px;
  }
}

// -------------------------------
// LOGIN DIALOG
// -------------------------------

.login-dialog {
  header {
    box-shadow: none;
  }
  .close-modal {
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
}

//-------------------------------
// No account page
//-------------------------------
#no-account-page {
  margin-top: 50px;
  height: 60vh;
  margin: 50px 45px 0 45px;

  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 15px;
    height: calc(100vh - 128px);
  }

  > div {
    height: 100%;
    > svg {
      max-width: 500px;
      width: 100%;
      float: right;
      margin-top: -60px;

      @media (max-width: 600px) {
        margin-top: 0;
      }
    }
  }

  a {
    color: #a4a3ee;
    font-weight: 600;
  }

  .alert-message {
    max-width: 350px;
  }
}
