.collection-tab {
  .MuiBox-root {
    padding: 14px 0 0 0;
  }
}

.collection-header {
  padding-bottom: 10px;
  button {
    margin-right: -10px;
    padding: 10px;
  }
}

// #head {
//   span {
//     font-size: 15px;
//   }
// }

// Collection drawer scroll settings
.collection-drawer {
  overflow: hidden;
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    right: -6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .collection-menu-container {
    // overflow-y: hidden;
    overflow-x: hidden;
    height: calc(100vh - 330px);
    // min-height: -webkit-fill-available;

    &:hover {
      overflow-y: auto;
    }
  }
}

.organization-list {
  margin-bottom: 12px !important;
  margin-left: -20px !important;
  max-height: 155px;
  overflow-y: scroll;

  .org-item {
    border: solid 1px transparent;
    margin-bottom: 0px;
    padding-left: 38px;
    padding-bottom: 4px;
    padding-top: 4px;

    span {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      transition: all 0.3s ease;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      // color: rgba(255, 255, 255, 0.6);
      opacity: 0.6;
    }
    svg {
      // color: rgba(255, 255, 255, 0.6);
      opacity: 0.6;
    }
    &:hover {
      background-color: transparent;
      span {
        // color: white;
        opacity: 1;
      }
      svg {
        // color: white;
        opacity: 1;
      }
    }
    &.selected-org-item {
      background-color: rgba(164, 163, 238, 0.1);
      svg {
        // color: white;
        opacity: 1;
      }
      span {
        // font-weight: 600;
        // color: white;
        opacity: 1;
      }
    }
  }
}

.video-list-container {
  padding-right: 10px;
  padding-bottom: 40px;
}

.menu-header {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #a4a3ee;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.collection-name {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  @media (max-width: $sm) {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  font-size: 18px;
  line-height: 21px;
  margin: 6px 0 0 0;
}

.video-list {
  padding-right: 10px !important;
  .sub-collection {
    margin: 3px -20px;
    width: 300px;
    padding: 0 20px;

    > div {
      font-size: 14px;
      min-width: 26px;
      svg {
        margin-bottom: 5px;
      }
    }

    &:hover {
      background-color: transparent;
    }
    .expand-icon {
      transition: all 0.3s ease;
      align-self: flex-start;
      margin-top: 2px;
      &.closed {
        transform: rotate(90deg);
      }
    }

    &.empty {
      opacity: 0.5;
    }
  }

  .video-link,
  .item-link {
    padding: 0;
    border: solid 1px transparent;

    span {
      transition: all 0.3s ease;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;

      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.7);
    }

    .MuiTypography-root {
      &:before {
        content: "";
        background: url("../../images/video-icon.svg") no-repeat;
        width: 23px;
        height: 23px;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: -7px;
      }
    }

    &:hover {
      background-color: transparent;
      span {
        color: white;
      }
    }
    &.selected-item {
      span {
        // font-weight: 600;
        color: #605df9;
      }
    }
  }

  .item-link {
    .MuiTypography-root {
      display: flex;
      &:before {
        content: "";
        background: url("../../images/file-icon.svg") no-repeat !important;
        background-position: center !important;
      }
    }
  }
}

.item-link {
}

.add-new-container {
  transition: background 0.3s ease;
  &.open {
    background: transparent !important;
  }
  .add-new-btn {
    > .MuiFab-root {
      width: 40px;
      height: 40px;
    }
  }
  .MuiSpeedDialAction-staticTooltipLabel {
    font-size: 14px;
    color: rgba(164, 163, 238, 0.6);
    box-shadow: none;
    // background: rgba(255, 255, 255, 0.03);
  }

  #Addnew-action-0 {
    .MuiSpeedDialAction-staticTooltipLabel {
      width: 130px;
    }
  }

  #Addnew-action-1 {
    .MuiSpeedDialAction-staticTooltipLabel {
      width: 160px;
    }
  }
  #Addnew-action-2 {
    .MuiSpeedDialAction-staticTooltipLabel {
      width: 115px;
    }
  }
  .add-new-text {
    padding-left: 56px;
    padding-top: 10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: rgba(164, 163, 238, 0.6);
    cursor: pointer;
  }
}

// -------------------
// Collection drawer
// -------------------

.collection-drawer {
  border: none !important;

  .drawer-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;

    .close-icon {
      color: #605df9;
      padding: 6px;
      margin-right: 2px;
    }

    .logo {
      // margin-right: 42px;
      display: flex;
      align-items: center;
    }
  }
}

.collection-drawer-alert {
  padding-right: 20px;
}

// -------------------
// end of Collection drawer
// -------------------

.no-data-container {
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    width: 100%;
    max-width: 750px;
    height: auto;
  }
}
