// Global variables
$primary-color: #43418d;
$secondary-color: #f50057;

$xs: 0px;
$xs-max: 599px;
$sm: 600px;
$sm-max: 959px;
$md: 960px;
$md-max: 1279px;

.file-display-frame {
  width: 100%;
  height: 1000px;
}

.MuiContainer-maxWidthLg {
  max-width: 1920px !important;
}

.search-box {
  .MuiAutocomplete-inputRoot {
    border: 1px solid transparent;
    padding: 6px 20px !important;
    background-color: #333341;
    border-radius: 15px;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:focus-within {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-color: #a4a3ee;
    }
    input {
      border: 1px solid transparent;
      padding-top: 6px !important;
      padding-bottom: 7px !important;
    }
  }
  .MuiInput-underline::after {
    display: none !important;
    content: "";
  }
  .MuiInput-underline::before {
    display: none !important;
    content: "";
  }
  button {
    padding: 5px;
    margin: -5px -10px -5px 10px;
  }
}

// Button styles
.MuiButton-root {
  &.MuiButton-contained {
    background-color: #6362ad !important;
  }
  //box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 4px 14px !important;
  border-radius: 7px !important;
  text-transform: none !important;
  transition: all 0.3s ease;

  .MuiButton-label {
    color: #fff;
    font-family: "Raleway";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  &.settings-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 4px !important;
    .MuiButton-label {
      font-size: 12px;
      font-weight: 400 !important;
      line-height: 0;
    }
  }

  &.light-purple {
    background-color: #a4a3ee !important;
    .MuiButton-label {
      color: black;
      font-weight: bold;
    }

    &:hover {
      box-shadow: 0px 0px 11px rgba(67, 65, 141, 0.8);
      background-color: #b6b5ee;
    }
  }

  &.outlined {
    background-color: transparent !important;
    border: 2px solid;
    margin-left: 15px;

    padding: 5px 13px !important;

    @media (max-width: $md-max) {
      margin-left: -10px;
    }
  }

  &.Mui-disabled {
    border: none !important;
    background-color: rgba(191, 190, 227, 0.2) !important;

    .MuiButton-label {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
.MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 14px !important;
}

h1,
h2,
h3 {
  font-family: "Raleway";
}

@import "./components/header.scss";
@import "./components/video_controls.scss";
@import "./components/collection_slider.scss";
@import "./components/landing_page.scss";
@import "./components/collection_container.scss";
@import "./components/user_menu.scss";

html {
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

.Signup {
  input {
    background-color: #fff;
    display: block;
    margin-bottom: 10px;
  }

  label {
    color: black;
  }
}

.MuiFormHelperText-root {
  font-size: 12px !important;
}

.logo {
  // float: left;
  // width: 50%;
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  svg {
    height: 35px;
    width: 35px;
    margin-right: 12px;
  }
}
.home {
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // align-items: center;
  // background: linear-gradient(
  //   228.47deg,
  //   rgba(0, 0, 0, 0.76) 70.03%,
  //   #343434 99%
  // );

  min-height: calc(100vh - 200px);
  min-height: -webkit-fill-available;
  margin: 10px auto 0px auto;
}

@media (min-width: 300px) {
  .MuiGrid-grid-sm-auto {
    flex-grow: 0 !important;
    max-width: none !important;
    flex-basis: auto !important;
  }
}

.main {
  min-height: 100vh;
}

#root {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
}

.landing-pagge {
  h1 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 75px;
  }
  h2 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 75px;
  }
  .text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 49px;
  }
}

.fancy-btn {
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 21px;
  align-items: center;
  text-align: center;
  background-color: #43418d;
  border-radius: 7px;
  border: none;
  padding: 8px 20px;
  color: #ffffff;
}

#anchor {
  position: absolute;
  bottom: 0;
}

.save {
  float: right;
  @media (max-width: $md-max) {
    margin-top: 5px !important;
    margin-right: 90px !important;
  }
}

// .MuiButton-root:hover {
//   background-color: #44418dab !important;
// }

// Input autofill styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #1c1c1c inset;
  transition: background-color 5000s ease-in-out 0s;
}

.progress-bar {
  pointer-events: none;
}

.footer {
  flex-grow: 1;
}

.link-heading {
  margin-top: 0px;
  &:before {
    content: "";
    background: url("../images/file-icon.svg") no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: -4px;
    margin-top: 7px;
  }
}

.link-desc {
  margin-bottom: 100px;
}

.link-button {
  margin-bottom: 100px;
}

// Okta login form
#okta-sign-in {
  margin-top: 0 !important;
}

.okta-sign-in-header {
  padding-top: 0 !important;
}

.drag-hover-top {
  border-top: 1px solid white !important;
  padding-top: -1px;
}

.drag-hover-bottom {
  border-bottom: 1px solid white !important;
  padding-bottom: -1px;
}

.sub-drag-hover {
  background-color: darken($color: #1f1c1c, $amount: 0.5) !important;
}

.list-end {
  padding: 4px;
  width: 100%;
  margin-top: 0px;
}

.button-primary {
  background: #43418d !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 8px 20px !important;
  border-radius: 7px !important;
  text-transform: none !important;
  color: #fff !important;
  font-family: "Raleway" !important;
  font-weight: bold !important;
  font-size: 18px !important;
  border: none !important;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
.drive-wrapper {
  div {
    display: inline;
    margin-left: calc(50% - 95px);
    width: fit-content;
    cursor: pointer;
  }
}

.google-folder-wrapper {
  div {
    display: inline;
    width: fit-content;
    cursor: pointer;
  }
}

.dropbox-chooser,
.dropbox-btn {
  line-height: 11px !important;
  text-decoration: none !important;
  box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
}

.dropbox-btn,
.dropbox-btn:link,
.dropbox-btn:hover {
  display: inline-block;
  height: 14px;
  font-family: "Lucida Grande", "Segoe UI", "Tahoma", "Helvetica Neue",
    "Helvetica", sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #636363;
  text-decoration: none;
  padding: 1px 7px 5px 3px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  border-bottom-color: #d4d4d4;
  background: #fcfcfc;
  background: -moz-linear-gradient(top, #fcfcfc 0%, #f5f5f5 100%);
  background: -webkit-linear-gradient(top, #fcfcfc 0%, #f5f5f5 100%);
  background: linear-gradient(to bottom, #fcfcfc 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#f5f5f5',GradientType=0);
}

.dropbox-btn {
  padding: 1px 7px 5px 3px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  border-bottom-color: #d4d4d4;
  display: inline-block;
  height: 14px;
  font-family: "Lucida Grande", "Segoe UI", "Tahoma", "Helvetica Neue",
    "Helvetica", sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #636363;
  background: linear-gradient(to bottom, #fcfcfc 0%, #f5f5f5 100%);
}

.dropbox-btn .btn-status {
  display: inline-block;
  width: 15px;
  height: 14px;
  vertical-align: bottom;
  margin: 0 5px 0 2px;
  background: transparent url(../images/dbx-saver-status.png) no-repeat;
  position: relative;
  top: 2px;
}

.dropbox-btn .btn-drive-status {
  display: inline-block;
  width: 15px;
  height: 14px;
  vertical-align: bottom;
  margin: 0 5px 0 2px;
  background-color: transparent;
  background-size: 15px, 14px;
  background-image: url(../images/drive.png);
  position: relative;
  top: 2px;
}

.okta-form-title {
  font-family: "Raleway" !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.okta-form-label label {
  font-family: "Open sans" !important;
  font-weight: normal !important;
  font-size: 16px !important;
}

.custom-checkbox label {
  font-family: "Open sans" !important;
  font-weight: normal !important;
  font-size: 14px !important;
}
.picker-dialog-bg {
  z-index: 200000 !important;
}

.picker-dialog {
  z-index: 200001 !important;
}
.auth-footer a {
  color: #43418d !important;
  font-family: "Open sans" !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

#okta-sign-in.auth-container.main-container {
  border: none !important;
}

.okta-sign-in-header {
  border: none !important;
}

// Backgrounds
.video-background {
  // background: rgba(0, 0, 0, 0.69);
  border-radius: 51px;
  //padding: 25px 34px 60px 34px;
  margin-left: -35px;
  margin-right: -35px;
  @media (min-width: $sm) {
    padding: 25px 14px 60px 34px;
  }

  @media (max-width: $sm) {
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px 5px 15px;
    border-radius: 0px 50px 0px 0px;
  }

  &.player-background {
    padding-bottom: 80px;
    border-radius: 51px 51px 0px 0px;
    min-height: calc(100vh - 138px);
    @media (max-width: $sm) {
      border-radius: 0px 50px 0px 0px;
    }
  }
}

.alert-message {
  .MuiAlert-standardInfo {
    background: linear-gradient(
      270deg,
      rgba(42, 201, 206, 0.047) 0%,
      rgba(88, 194, 217, 0.1) 100%
    );
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

    .MuiAlert-icon {
      color: #58c2d9;
    }
    .MuiAlert-message {
      color: #bbf3ff;
      font-size: 14px;
    }
  }
}
