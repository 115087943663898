.user-menu-button {
  margin-right: 0px !important;
  // &:after {
  //   content: "";
  //   background: url(../../images/chevron-right.svg) no-repeat;
  //   background-size: contain;
  //   height: 30px;
  //   width: 30px;
  //   display: block;
  //   position: absolute;
  //   margin-left: 57px;
  // }
}

.user-menu-drawer {
  .header {
    display: flex;
    justify-content: space-between;
    background: $primary-color;

    .title {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;

      color: #ffffff;
      padding: 17px 16px;
    }

    .close-button {
      height: 57px;
      width: 57px;
      color: white;
    }
  }

  .MuiDivider-root {
    margin: 0 16px;
  }

  .user-name {
    margin-bottom: 8px;
    // User icon
    .MuiSvgIcon-root {
      background-color: $primary-color;
      border-radius: 50%;
      color: #a4a3ee;
      height: 25px;
      width: 25px;
    }
  }
}

// Dialogs
.MuiDialogTitle-root {
  h2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .department-name {
      font-size: 18px;
      line-height: 25px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
    }
  }
}
