.video-frame {
  transition: all 0.3s ease;
  margin-top: 20px;
  margin-bottom: 40px;
  @media (max-width: $sm) {
    margin-bottom: 0px;
  }

  &.new-video {
    margin-bottom: 10px;
    margin-top: 0px;
  }

  &.selected {
    background-color: #43418d;
    border-radius: 7px;
    padding: 10px;
    @media (max-width: $sm) {
      margin-left: -10px;
      margin-right: -10px;
    }

    .segment-name {
      background: #43418d;
      padding: 15px 25px;
      border-radius: 20px;
      position: absolute;
      z-index: 1;
      margin-top: -28px;
      margin-left: -10px;

      @media (max-width: $sm) {
        padding: 7px 10px;
        margin-top: -24px;
        margin-left: -10px;
        border-radius: 10px;

        h2 {
          font-size: 14px !important;
          font-weight: 400;
          line-height: 1.1;
        }
      }
    }

    .exit-segment-btn {
      button {
        float: right;
        margin-top: -10px;
        margin-right: -10px;
        z-index: 1;
        .MuiButton-label {
          font-size: 12px;
          letter-spacing: 1px;
        }
        svg {
          font-size: 20px;
          margin-top: -2px;
        }
      }
    }
  }
}

.video-container {
  background-color: #090909;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before {
    content: "";
    background: url("../../assets/icons/Note.svg") no-repeat;
    background-size: contain;
    height: 150px;
    width: 150px;
    display: block;
    position: absolute;

    top: calc(50% - 75px);
    left: calc(50% - 75px);
  }
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

// .fullscreen {
//   position: fixed !important;
//   top: 0 !important;
//   left: 0 !important;
//   max-height: calc(100% - 125px) !important;
//   margin: 10px !important;
//   width: 80% !important;
// }

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-input {
  .input-box {
    width: 100%;
  }
}

.player-slider {
  width: 100%;
  margin-bottom: -5px;
  margin-top: 16px;
  transition: all 0.3s ease;
  @media (max-width: $sm) {
    margin-bottom: -8px;
  }
  .MuiSlider-rail,
  .MuiSlider-track,
  .MuiLinearProgress-root {
    transition: height 0.3s ease !important;
  }

  .MuiSlider-thumb {
  }

  &.zooming {
    .MuiSlider-thumb {
      transition: all 0.3s ease;
    }
    .MuiSlider-track {
      transition: all 0.3s ease !important;
    }
  }

  &.zoomed {
    margin-bottom: 10px;
    .MuiSlider-rail,
    .MuiSlider-track,
    .MuiLinearProgress-root {
      height: 18px !important;
    }
    .MuiSlider-thumb {
      // border-radius: 22%;
      width: 8px;
      height: 32px;
    }
  }
}

.zoom-btn {
  .zoom-in-icon {
    height: 32px;
    width: 32px;
    display: block;
    background: url(../../images/zoom-in-icon.svg) no-repeat;
  }

  &.Mui-disabled {
    .zoom-in-icon {
      height: 32px;
      width: 32px;
      display: block;
      background: url(../../images/zoom-in-disabled-icon.svg) no-repeat;
    }
  }

  .zoom-out-icon {
    height: 32px;
    width: 32px;
    display: block;
    background: url(../../images/zoom-out-icon.svg) no-repeat;
  }
}

.left-control-container {
  margin-right: -10px !important;
  display: flex;
}

.progress-value {
  text-align: center;
}

.right-control-container {
  margin-top: -3px !important;
}

.add-new-seg-btn,
.save-seg-btn {
  background-color: transparent !important;

  &:hover {
    background-color: rgba(164, 163, 238, 0.1) !important;
  }

  .MuiButton-label {
    display: flex;
    color: #6d6d9a;
    font-weight: 700;
    .MuiSvgIcon-root {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: $primary-color;
      padding: 3px;
      margin-right: 10px;
      color: white;
      margin-left: -18px;
    }
  }

  &.Mui-disabled {
    background-color: transparent !important;
    .MuiSvgIcon-root {
      opacity: 0.3;
    }
  }
}

.save-seg-btn {
  svg {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid $primary-color;
    padding: 3px;
    margin-right: 10px;
    color: white;
    margin-left: -18px;
  }
}

.mobile-con.is-adding {
  display: none !important;
}

.mobile-con {
  @media (max-width: 650px) {
    display: none;
  }
  @media (min-width: 650px) {
    .player-slider {
      margin-bottom: -20px;
    }

    width: 100% !important;
    bottom: 0;
    right: 0;
    max-width: 100% !important;
  }
}

.mobile-player {
  @media (min-width: 650px) {
    display: none;
  }
}

.mobile-seg {
  @media (min-width: 650px) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background: black;
    padding: 10px;
    max-width: none !important;
    width: calc(30% - 20px);
    .right {
      margin-left: -14px !important;
    }
    .segment {
      height: 35px !important;
    }

    .segment-list-header {
      border-bottom: 2px solid #605df9 !important;
    }

    .segment-list {
      max-height: calc(100% - 125px) !important;
      height: 100% !important;
      overflow-y: auto !important;
      margin-right: 0px !important;
      padding-right: 14px !important;
      font-size: 14px !important;
      font-style: "open";

      .note-btn {
        margin-left: -10px;
        padding: 7px !important;
        font-size: 22px;
      }

      .MuiListItemText-primary {
        font-weight: 400 !important;
        font-size: 14px !important;
      }

      .mastered-checkbox {
        span {
          svg {
            font-size: 22px;
            width: 22px;
            height: 22px;
          }
        }
        font-size: 22px !important;
        margin-right: 0 !important;
        padding: 0px !important;
        transition: opacity 0.3s ease;
        height: 35px !important;
        width: 35px !important;
      }
    }

    .segment-container {
      .time {
        display: none !important;
      }
    }
  }
}
.fullscreen-seg {
  position: fixed;
  top: 180px;
  right: 0;
  height: 100%;
  background: black;
  padding: 10px;
  width: calc(20% - 24px);
  .right {
    margin-left: -14px !important;
  }
  .segment {
    height: 35px !important;
  }

  .segment-list-header {
    border-bottom: 2px solid #605df9 !important;
  }

  .segment-list {
    max-height: calc(100% - 340px) !important;
    height: 100% !important;
    overflow-y: auto !important;
    margin-right: -0px !important;
    padding-right: 10px !important;
    font-size: 14px !important;
    font-style: "open";

    .note-btn {
      margin-left: -10px;
      padding: 7px !important;
      font-size: 22px;
    }

    .MuiListItemText-primary {
      font-weight: 400 !important;
      font-size: 14px !important;
    }

    .mastered-checkbox {
      span {
        svg {
          font-size: 22px;
          width: 22px;
          height: 22px;
        }
      }
      font-size: 22px !important;
      margin-right: 0 !important;
      padding: 0px !important;
      transition: opacity 0.3s ease;
      height: 35px !important;
      width: 35px !important;
    }
  }

  .segment-container {
    .time {
      display: none !important;
    }
  }
}

.segment-list-container {
  /* width */

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 7px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (min-width: $md-max) {
    padding-left: 10px;
  }
  @media (max-width: $md-max) {
    margin-bottom: 100px !important;
  }

  .segment-list-header {
    border-bottom: 2px solid #605df9;
    padding-bottom: 4px;

    @media (max-width: $md-max) {
      border: none;
    }
    h3 {
      font-size: 16px;
      font-family: Raleway;
      font-weight: 600;

      align-self: flex-end;
      margin: 0;
    }
  }
  .segment-list-opt {
    text-align: right;
    margin-bottom: -10px;
    float: right;
    svg {
      width: 0.7em;
      height: 0.7em;
    }
  }

  .segment-list {
    //margin-top: 20px !important;

    @media (min-width: $md-max) {
      max-height: calc(100vh - 374px);
      height: 100%;
      overflow-y: auto;
      margin-right: -14px;
      padding-right: 14px;
    }
  }

  .segment-container {
    position: relative;
    // z-index: 2;
    @media (min-width: $md-max) {
      // margin-top: -10px;
    }
    &.mastered {
    }

    .time-top {
      display: none;
      float: right;
      padding: 3px 0;
      font-size: 12px;
      .MuiSvgIcon-root {
        margin-bottom: -3px;
        font-size: 16px;
      }
      @media (max-width: $md-max) {
        display: none;
      }
    }
    .segment {
      transition: all 0.3s ease;

      &.Mui-selected {
        .edit-btn .MuiSvgIcon-root {
          color: white !important;
        }
      }

      .right {
        right: 0;
        position: absolute;
        .time {
          font-size: 14px;
          .MuiSvgIcon-root {
            margin-bottom: -3px;
            font-size: 16px;
          }
        }
        .note-btn {
          padding: 10px;
        }
        .mastered-checkbox {
          margin-right: 0;
        }
      }

      // If segment is mastered
      &.mastered {
        // background-color: rgba(191, 190, 227, 0.2) !important;
        border: solid 1px transparent;
        border-radius: 7px;
        background-image: linear-gradient(black, black),
          linear-gradient(233.3deg, #ff0d63 -28.04%, #605df9 123.21%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        padding: 1px;

        .MuiListItemText-root {
          .MuiTypography-root {
            background: -webkit-linear-gradient(
              233.3deg,
              #ff0d63 -28.04%,
              #605df9 123.21%
            );
            font-weight: 700 !important;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }
        }

        &.Mui-selected {
          .MuiTypography-root {
            -webkit-text-fill-color: white;
          }
        }
        // .time {
        //   color: black;
        //   .MuiSvgIcon-root {
        //     color: black;
        //   }
        // }

        .note-btn {
          svg {
            // color: black !important;
          }
        }
        .mastered-checkbox {
          svg {
            path {
              fill: #d53597;
            }
          }
        }

        .edit-btn .MuiSvgIcon-root {
          // color: black !important;
        }
        .segment-form .editSeg {
          // color: black !important;
        }
      }

      .segment-form {
        display: flex;

        .editSeg {
          padding-top: 5px;
          padding-bottom: 4.5px;
          margin-left: 8px;
          color: white;
          font-size: 16px;
          font-style: normal;
          font-family: "Open sans";
          font-weight: 600;
          background: rgba(76, 175, 80, 0);
          border: none;
          border-width: 0px;
          box-shadow: none;
          border-color: rgba(76, 175, 80, 0);
          border-image-width: 0;
          width: 100%;

          @media (min-width: $md-max) {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .editSeg:focus {
          outline: none;
        }

        input::-webkit-input-placeholder {
          font-weight: normal !important;
          color: rgba(196, 196, 196, 0.5) !important;
        }
        input::placeholder {
          font-weight: normal !important;
          color: rgba(196, 196, 196, 0.5) !important;
        }
        input:-ms-input-placeholder {
          font-weight: normal !important;
          color: rgba(196, 196, 196, 0.5) !important;
        }
        input::-ms-input-placeholder {
          font-weight: normal !important;
          color: rgba(196, 196, 196, 0.5) !important;
        }

        .edit-btn {
          height: 100%;
          margin-top: 2px;
        }
      }
    }

    .segment-note {
      background: rgba(164, 163, 238, 0.19);
      border-radius: 15px;
      padding: 10px 20px;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 10px;
      transition: all 0.3s ease;

      &::before {
        content: "";
        z-index: 900;
        position: absolute;
        top: 37px;
        right: 44px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 20px 15px;
        border-color: transparent transparent #272735 transparent;
        transition: top 0.3s ease, opacity 0.3s ease;
      }

      .note-form {
        .note-input {
          padding-top: 5px;
          padding-bottom: 4.5px;
          padding-right: 20px;
          margin-left: -2px;
          color: white;
          font-size: 16px;
          font-family: "Open sans";
          font-weight: 300;
          background: rgba(76, 175, 80, 0);
          border: none;
          border-width: 0px;
          box-shadow: none;
          border-image-width: 0;
          width: 100% !important;
          max-height: 400px;

          @media (min-width: $md-max) {
            font-size: 14px;
          }

          &:focus {
            outline: none;
          }
        }
      }

      &.hidden {
        &::before {
          top: 46px;
          opacity: 0;
        }
      }

      @media (min-width: $md-max) {
        &::before {
          top: 27px;
          right: 32px;
          border-width: 0 12px 18px 12px;
        }
      }
    }
    @media (max-width: $md-max) {
      .mastered-checkbox {
        transition: opacity 0.3s ease;
        height: 45px;
        width: 45px;
      }
    }

    @media (min-width: $md-max) {
      .edit-btn {
        .MuiIconButton-sizeSmall {
          padding: 3px !important;
        }
        .MuiIconButton-label {
          width: 22px;
          height: 22px;
        }
      }

      .right {
        .time {
          display: none;
        }
        .note-btn {
          transition: opacity 0.3s ease;
          padding: 7px !important;
          .MuiIconButton-label {
            height: 22px;
            width: 22px;
          }
        }
        .mastered-checkbox {
          transition: opacity 0.3s ease;

          .MuiIconButton-root {
            padding: 7px;
          }
          .MuiIconButton-label {
            height: 22px;
            width: 22px;
          }
          .MuiSvgIcon-root {
            height: 22px;
            width: 22px;
          }
        }

        &.isEditing {
          .note-btn {
            display: none;
            opacity: 0;
          }

          .mastered-checkbox {
            display: none;
            opacity: 0;
          }
        }
      }
    }
  }
}
.add-new-seg-container {
  // margin-top: -30px;
}

.player-toolbar {
  border-color: #43418d;
  border-width: 5px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  // padding-bottom: 10px;
  .center {
    text-align: center;
  }
}

.speed-control-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-right: 8px;

  @media (max-width: $sm) {
    margin-right: 0;
  }
  .MuiSelect-select {
    font-weight: 400;
    font-size: 14px;
  }
  .MuiInputLabel-outlined {
    color: #a4a3ee !important;
    margin: 0;
    margin-top: -2px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.loop-switch {
  margin: 0 !important;
  svg {
    transition: transform 0.3s ease;
    path {
      fill: white;
    }
  }

  .Mui-checked {
    svg {
      transform: rotate(-180deg);
      path {
        fill: #605df9;
      }
    }
  }
}

.add-new-video {
  padding: 0 4px;
  margin: 0 -2px;
  .add-new-video-controls {
    .control-container-top {
      display: flex;
      flex-flow: row wrap;
      .play-control {
        // align-self: flex-start;
        // display: inline-flex;
        order: 1;
      }
      .trim-control {
        order: 2;
        text-align: center;
        min-width: 200px;
      }
      & > div {
        flex: 1;
      }
      & > div:last-of-type {
        order: 3;
      }
    }
    .control-container-bottom {
      display: flex;
      justify-content: center;
      .interval-control {
        flex: 1;
        text-align: center;
        margin-right: -20px;
      }
    }
  }
}

.control-add-btn-container {
  display: inline-block;
  margin-left: 8px;
}

.control-text-btn {
  span {
    font-size: 14px !important;
  }
}

.YT-text-btn {
  span {
    margin-top: -10px i !important;
    font-size: 10px !important;
  }
}
.fullsc {
  .video-sub-collection-name {
    width: calc(100%);
    position: fixed;
    background: black;
    padding-top: 40px;
    padding-bottom: 100%;
    right: 0;
    padding-left: calc(80% + 35px);
    top: 0px;
    font-size: 11px;
    button {
      position: fixed;
      top: 0;
      right: 0;
      float: none !important;
      margin: 0 !important;
    }
    .fullscreen-button {
      margin-right: 50px !important;
    }
  }
  .video-name {
    width: calc(20% - 46px);
    position: fixed;
    height: 115px;
    font-size: 18px;
    margin-top: 0px;
    overflow: hidden;
    background: black;
    right: 10px;
    top: 64px;
  }
  .backnforth {
    width: calc(20% - 45px);
    position: fixed;
    background: black;
    right: 10px;
    top: 135px;
  }
}

.video-sub-collection-name {
  /*position: absolute; */
  margin: 0;
  margin-bottom: -5px;
  margin-left: 50px;
  /* margin-top: 30px; */
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: 600;
  color: #a4a3ee;

  .collection {
    color: #686868;
  }

  @media (max-width: 600px) {
    margin-left: 0;
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.video-name {
  margin: 0;
  font-family: Raleway;
  font-style: normal;

  font-size: 24px;
  font-weight: bold;
  margin-left: 50px;
  text-indent: -50px;
  line-height: 1.3;

  @media (max-width: 600px) {
    font-size: 16px;
    padding-left: 0;
    margin-top: 10px;
    margin-left: 29px;
    text-indent: -29px;
  }

  &:before {
    content: "";
    background: url("../../images/video-icon.svg") no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: -12px;
    @media (max-width: 600px) {
      margin-right: 8px;
      margin-bottom: -5px;
      width: 20px;
      height: 20px;
    }
  }
}

.video-description {
  font-family: Raleway;
  font-style: normal;
  font-size: 14px;
  // margin-bottom: 28px;
}

.mobile-frame {
  @media (min-width: 650px) {
    margin: 0;
    position: fixed;
    width: calc(70% + 20px);
    height: calc(100% - 100px);
    top: 0;
    left: 0;
    iframe {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      max-height: calc(100% - 120px) !important;
      margin: 10px !important;
      width: 70% !important;
    }
    video {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      max-height: calc(100% - 120px) !important;
      margin: 10px !important;
      width: 70% !important;
    }
  }
}

.fullscreen-frame {
  margin: 0;
  margin-left: 4px;
  position: fixed;
  width: calc(80% + 20px);
  height: calc(100% - 105px);
  top: 0;
  left: 0;
  iframe {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    max-height: calc(100% - 125px) !important;
    margin: 10px !important;
    width: 80% !important;
  }
  video {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    max-height: calc(100% - 125px) !important;
    margin: 10px !important;
    width: 80% !important;
  }

  &.selected {
    border-radius: 0;

    .segment-name {
      background: rgba(67, 65, 141, 0.8);
      padding: 22px 20px 15px;
      padding-left: 50px;
      border-radius: 0 0 15px 0;
      position: absolute;
      z-index: 1;
      margin-top: -10px;
      margin-left: -6px;

      h2 {
        font-size: 20px;
      }
    }

    .exit-segment-btn {
      button {
        background: #43418d;
      }
    }
  }
}

.fullscreen-controls {
  width: 100% !important;
  bottom: 0;
  right: 0;
  max-width: 100% !important;
}

// PLAYER TOOLBAR CONTAINER
.fixed-video-control {
  /*max-width: 800px; */
  /* max-width: 1872px; */
  /* flex-basis: 75%; */
  /* width: calc(100% - 300px - 36%); */
  max-width: 1385px; // MENU CLOSED
  width: calc(100% - 70px - 24%); // MENU CLOSED

  position: fixed;

  bottom: 0;
  background: rgba(10, 10, 10, 0.89);
  z-index: 1000;
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  @media (max-width: 1340px) {
    width: calc(100% - 70px); // MENU CLOSED
  }

  @media (max-width: $sm) {
    width: 100%;
    max-width: 100%;
    margin-left: -16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  &.drawer-open {
    max-width: 1160px; // MENU OPEN
    width: calc(100% - 300px - 24%); // MENU OPEN

    @media (max-width: 1340px) {
      width: calc(100% - 360px); // MENU OPEN
    }
  }
}
