.header {
  display: flex;
  width: 100%;
  height: 62px;
  align-items: center;
  background: transparent;
  justify-content: space-between;

  .logo {
    // float: left;
    // width: 50%;
    // padding-top: 22px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-left: 45px;
    @media (max-width: 600px) {
      margin-bottom: 20px;
      font-size: 16px;
      margin-left: 15px;
      margin-top: 20px;
      display: flex;
    }

    svg {
      width: 40px;
      height: 35px;
      margin-bottom: -7px;
      margin-right: 12px;
      @media (max-width: 600px) {
        width: 24px;
        margin-top: -6px;
      }
    }
  }

  .right {
    // width: 50%;
    text-align: right;
    // padding-top: 12px;
    padding-right: 10px;
    margin-right: 15px;
    @media (max-width: $sm) {
      width: 100%;
      margin-right: 0;
    }

    .fancy-btn {
      margin-left: 14px;
    }
  }

  .practice-mode {
    &.checked {
      color: $secondary-color;

      .MuiFormControlLabel-label {
        font-weight: 400;
      }
    }
  }

  .workspace-select {
    padding: 10px 12px 8px;
    font-size: 14px;
    text-align: center;
  }
}
