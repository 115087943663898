// -------------------------
// Collection tabs
// -------------------------

// --- Animations start ---
@keyframes slideIn {
  from {
    width: 0;
  }
  to {
    width: 40px;
  }
}

@keyframes slideOut {
  from {
    width: 40px;
  }
  to {
    width: 0px;
  }
}
// --- Animations end---
// .MuiTabs-scroller {
//   transition: all 0.2s ease;
// }
// .MuiTabs-scrollButtonsDesktop {
//   animation-name: slideOut;
//   animation-duration: 0.3s;
//   width: 0 !important;
//   height: 40px;
//   // transition: width 0.3s ease !important;

//   &.MuiButtonBase-root {
//     animation-name: slideIn !important;
//     animation-duration: 0.3s;
//     width: 40px !important;
//     height: 60px;
//   }
// }

.collection-tab-container {
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.MuiTabs-root {
  display: inline-flex !important;
  max-width: calc(100% - 45px) !important;
  margin-right: -18000px;
  align-self: center;

  > .MuiTabScrollButton-root {
    position: absolute;
    margin-left: -40px;
    height: 60px;
    border-radius: 7px;
  }

  > .MuiTabScrollButton-root ~ .MuiTabScrollButton-root {
    position: relative;
    margin-left: 0;
    // margin-right: -16px;
  }
}

.MuiTabs-indicator {
  bottom: 3px !important;
  opacity: 0.5;
}

.add-collection-btn {
  display: inline-flex;
  margin-left: 18000px;
  margin-right: -18000px;
  height: 45px;
  button.MuiIconButton-root {
    padding: 5px;
  }
}

.collection-tab {
  .collection-name {
    transition: margin 0.3s ease;
  }
  .delete-collection {
    transition: all 0.3s ease;
    margin-top: 4px !important;
    position: absolute !important;
    visibility: hidden;
    width: 0;
    height: 16px;
    padding: 10px 0;
    opacity: 0;
    color: #000;

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }

  &:hover {
    .collection-name {
      // margin-left: -8px;
      // margin-right: 8px;
    }
    .delete-collection {
      visibility: visible;
      opacity: 1;
      width: 16px;
      padding: 10px;
      &:hover {
        color: #43418d;
      }
    }
  }
  &.Mui-selected {
    .delete-collection {
      color: white !important;
    }
  }
}

.add-collection-btn {
  // margin-top: 0px;
  // margin-left: -7px !important;
  .MuiIconButton-label {
    background: #43418d;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    color: white;
  }
}
